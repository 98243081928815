'use client';

import { Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useMutateLogout } from '@/app/lib/mutation';
import Logo from '@/components/atoms/Logo';
import { NavigateRouter, classNames } from '@/utils';
const HeaderPC = dynamic(() => import('./HeaderPC'));
const HeaderSP = dynamic(() => import('./HeaderSP'));
export default function Header() {
  const {
    t
  } = useTranslation();
  const {
    mutateAsync
  } = useMutateLogout();
  const router = useRouter();
  const pathname = usePathname();
  const userNavigation = [{
    name: t('header.Post'),
    onClick: () => router.push(NavigateRouter.SELLERNET)
  },
  // { name: t('common.Profile'), onClick: () => navigate(NavigateRouter.PROFILE) },
  {
    name: t('common.Sign out'),
    onClick: () => mutateAsync({}).finally(() => router.push(NavigateRouter.HOME))
  }];
  const navigation = [{
    name: t('header.Buy'),
    href: NavigateRouter.REAL_ESTATE_FOR_SALE,
    current: false
  }, {
    name: t('header.Sell'),
    href: NavigateRouter.REAL_ESTATE_FOR_RENT,
    current: false
  }, {
    name: t('header.News'),
    href: NavigateRouter.NEWS,
    current: false
  }];
  return <>
      <Popover as="nav" className="fixed w-full z-50 border-b border-gray-200 bg-violet-bds">
        {({
        open
      }) => <>
            <div className="mx-auto max-w-[99%] px-4 lg:px-6">
              <div className="flex h-16 justify-between">
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
                  </Popover.Button>
                </div>
                <div className="flex justify-center">
                  <Link href={NavigateRouter.HOME} className="flex flex-shrink-0 items-center">
                    <Logo src={'/images/logo/Logo-BDS-369-t-wh.svg'} alt="bds-logo" className="block h-12 lg:h-16 w-auto" />
                  </Link>
                  <div className="hidden sm:-my-px sm:ml-6 lg:flex sm:space-x-8">
                    {navigation.map(item => <Link key={item.name} href={item.href} className={classNames(pathname.includes(item.href) ? 'border-yellow-bds text-yellow-bds' : 'border-transparent text-white hover:border-yellow-bds', 'inline-flex items-center border-b-2 px-1 pt-1 text-lg font-bold hover:text-violet-300')} aria-current={item.current ? 'page' : undefined}>
                        {item.name}
                      </Link>)}
                  </div>
                </div>
                <HeaderPC userNavigation={userNavigation} />
              </div>
            </div>

            <HeaderSP userNavigation={userNavigation} navigation={navigation} />
          </>}
      </Popover>
    </>;
}