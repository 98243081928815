import type { SignInInput } from '@/schema/SignIn/type'
import type { SignUpInput } from '@/schema/SignUp/type'
import type { ResponseApi, User } from '@/types'

import { API_USER_URL } from '@/common'
import axiosInstance from '@/config/axiosInstance'

export const oauthService = {
  signIn: async (formData: SignInInput) => {
    try {
      const { data } = await axiosInstance.post<ResponseApi<User>>(API_USER_URL.OAUTH_LOGIN, formData)
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  signUp: async (formData: SignUpInput) => {
    try {
      const { data } = await axiosInstance.post<ResponseApi<User>>(API_USER_URL.OAUTH_REGISTER, formData)
      return data
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  }
}
