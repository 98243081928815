import { useQueryClient, useMutation } from '@tanstack/react-query'

import { removeUserFromLocalStorage, setUserToLocalStorage } from './query'

import type { SignInInput } from '@/schema/SignIn/type'
import type { SignUpInput } from '@/schema/SignUp/type'
import type { UseMutationResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import { refreshTokenKeyLocalStorage, tokenKeyLocalStorage } from '@/common'
import { HttpStatusEnum } from '@/enum'
import { oauthService } from '@/services/oauth'
import { userService } from '@/services/user'
import { userQueryKey, type ResponseApi, type User } from '@/types'

export const useMutateLogout = (): UseMutationResult<ResponseApi<User>, AxiosError> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: userService.logout,
    onMutate: () => {
      removeUserFromLocalStorage()
      queryClient.removeQueries()
    }
  })
}

export const useMutateSignIn = (): UseMutationResult<ResponseApi<User>, AxiosError, SignInInput, undefined> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: oauthService.signIn,
    onSuccess: (data) => {
      if (data.http_status === HttpStatusEnum.SUCCESS) {
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(tokenKeyLocalStorage, data.data?.token as string)
          window.localStorage.setItem(refreshTokenKeyLocalStorage, data.data?.refresh_token as string)
        }
        queryClient.removeQueries()
        setUserToLocalStorage(data.data?.id as string)
        queryClient.setQueryData([userQueryKey], data)
      }
    }
  })
}

export const useMutateSignUp = (): UseMutationResult<ResponseApi<User>, AxiosError, SignUpInput, undefined> => {
  return useMutation({ mutationFn: oauthService.signUp })
}
